import React from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import Footer from './Footer/Footer';  

const images = [
  'https://images.pexels.com/photos/355853/pexels-photo-355853.jpeg?auto=compress&cs=tinysrgb&w=600',
  'https://img.freepik.com/premium-vector/dark-blue-vector-template-with-circles_6869-1106.jpg?w=740',
  'https://images.pexels.com/photos/2516772/pexels-photo-2516772.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load'
];

const quotes = [
  {
    title: <strong>Welcome to Haryak Technologies</strong>,
    text: "Empowering Tomorror's Solutions, Today ."
  },
];

const HomePage = () => {
  return (
    <div className="homepage-container">
      <div className="position-relative vh-100">
        <Carousel controls={false} indicators={false} interval={3000} pause={false} className="vh-100">
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <div
                className="vh-100"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="position-absolute top-50 start-50 translate-middle text-center text-white">
          <Carousel controls={false} indicators={false} interval={3000} pause={false}>
            {quotes.map((quote, index) => (
              <Carousel.Item key={index}>
                <div>
                  <h1 className="display-4 font-weight-bold">{quote.title}</h1>
                  <p className="lead">{quote.text}</p>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <h2 className="mt-5">Join Us Today!</h2>
          <p className="mb-4">Learn more about our services and how we can help your business grow.</p>
          <Link to="/our-vision" className="btn btn-lg glow-button mb-5">Get Started</Link>
        </div>
      </div>
     
      <Footer />
    </div>
  );
};

export default HomePage;
