import React from 'react';
import Footer from './Footer/Footer';
import './RefundandCancellationPolicy.css'; // Import the CSS file

const Refund = () => {
  return (
    <div className="refund-container">
      <div className="refund-content">
        <h1>Refund and Cancellation Policy</h1>
        <p>
          This refund and cancellation policy outlines how you can cancel or seek a refund for a product/service 
          that you have purchased through the Platform. Under this policy:<br /><br />
          
          1. Cancellations will only be considered if the request is made within 7 days of placing the order. However, 
          cancellation requests may not be entertained if the orders have been communicated to such sellers/merchant(s) 
          listed on the Platform and they have initiated the process of shipping them, or the product is out for delivery. 
          In such an event, you may choose to reject the product at the doorstep.<br /><br />
          
          2. Haryak Technology India Private Limited does not accept cancellation requests for perishable items like flowers, 
          eatables, etc. However, the refund/replacement can be made if the user establishes that the quality of the product 
          delivered is not good.<br /><br />
          
          3. In case of receipt of damaged or defective items, please report to our customer service team. The request would 
          be entertained once the seller/merchant listed on the Platform has checked and determined the same at its own end. 
          This should be reported within 7 days of receipt of products. In case you feel that the product received is not as 
          shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 days 
          of receiving the product. The customer service team, after looking into your complaint, will take an appropriate decision.<br /><br />
          
          4. In case of complaints regarding the products that come with a warranty from the manufacturers, please refer the issue 
          to them.<br /><br />
          
          5. In case of any refunds approved by <b>HARYAK TECHNOLOGY INDIA PRIVATE LIMITED</b>, it will take 5 days for the refund 
          to be processed to you.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Refund;
