import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [cartCount, setCartCount] = useState(0);

  const addToCart = (product) => {
    // Check if the product is already in the cart
    const existingItem = cartItems.find(item => item.id === product.id);

    if (existingItem) {
      // If item exists, increase its quantity
      const updatedCart = cartItems.map(item =>
        item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
      );
      setCartItems(updatedCart);
      setCartCount(prevCount => prevCount + 1); // Increment cartCount
    } else {
      // If item doesn't exist, add it to the cart
      setCartItems(prevItems => [...prevItems, { ...product, quantity: 1 }]);
      setCartCount(prevCount => prevCount + 1); // Increment cartCount
    }
  };

const removeFromCart = (itemId) => {
  const itemToRemove = cartItems.find(item => item.id === itemId);
  if (itemToRemove) {
    const updatedCart = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCart);
    setCartCount(prevCount => prevCount - itemToRemove.quantity); // Decrement cartCount by the quantity of the removed item
  }
};

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, cartCount }}>
      {children}
    </CartContext.Provider>
  );
};
