import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import Footer from './Footer/Footer';

const WebDevelopment = () => {
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });

  const handleClose = () => {
    setShow(false);
    setSubmitted(false);
  };
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;

    emailjs.sendForm('service_0ibcerg', 'template_rynwrfg', form, 'Y-xychfUUEkG6e5gj')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSubmitted(true);
        setTimeout(() => {
          setShow(false);
          setSubmitted(false);
        }, 2000);
      })
      .catch((error) => {
        console.log('FAILED...', error);
      });
  };

  return (
    <div className="container my-5" style={{ backgroundColor: '#4169e1' }}>
      <div className="row align-items-center">
        <div className="col-md-6" style={{paddingTop: '20px', paddingBottom: '20px'}}>
          <img src="https://tse1.mm.bing.net/th/id/OIP.xi_VY54V535hztHz11VTyQHaFL?rs=1&pid=ImgDetMain" alt="Web Development" className="img-fluid rounded-lg" />
        </div>
        <div className="col-md-6">
          <h1 className="display-4 font-weight-bold mb-4" style={{ color: 'white' }}><strong>Web Development</strong></h1>
          <p className="lead mb-4" style={{ color: 'white' }}>
            Our team of experienced web developers specializes in crafting visually
            stunning and highly functional websites that drive business growth.
          </p>
          <p className="mb-4" style={{ color: 'white' }}>
            From custom web applications to responsive e-commerce solutions, we
            leverage the latest technologies and best practices to deliver
            exceptional digital experiences that exceed our clients' expectations.
          </p>
          <div className="d-flex flex-column flex-md-row mb-4">
            <Button variant="btn btn-warning" size="lg" onClick={handleShow} style={{ color: 'black' , borderColor: 'black'}}>
              Get a Quote
            </Button>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Custom Web Applications</h4>
              <p className="card-text">
                We develop custom web applications tailored to your unique business
                requirements, ensuring seamless functionality and a superior user experience.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Responsive Design</h4>
              <p className="card-text">
                Our web solutions are designed to be mobile-friendly, ensuring your
                website looks and performs exceptionally well across all devices.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">E-commerce Solutions</h4>
              <p className="card-text">
                From online stores to complex e-commerce platforms, we build
                innovative solutions that streamline your online sales and
                enhance the customer experience.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get a Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submitted ? (
            <Alert variant="success">Your request has been submitted!</Alert>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMobile">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={3}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
          <Footer />
    </div>
  );
};

export default WebDevelopment;