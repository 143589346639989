import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './Components/NavBar';
import Home from './Components/Home';
import Carrers from './Components/Carrers';
import WebDevelopment from './Components/WebDevelopment';
import Consulting from './Components/Consulting';
import Contact from './Components/Contact';
import Schedule from './Components/Schedule';
import About from './Components/About';
import TechSolutions from './Components/TechSolutions';
import DataAnalytics from './Components/DataAnalytics';
import SoftwareDevelopment from './Components/SoftwareDevelopment';
import VisionPage from './Components/VisionPage';
import { Routes, Route } from 'react-router-dom';
import Cart from './Components/Cart';
import Products from './Components/Products';
import TermsConditions from './Components/TermsConditions';
import RefundandCancellationPolicy from './Components/RefundandCancellationPolicy';
import PrivacyPolicy from './Components/PrivacyPolicy';
import ReturnPolicy from './Components/ReturnPolicy';
import ShippingPolicy from './Components/ShippingPolicy';

import JobApplicationForm from './Components/JobApplicationForm';

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/careers' element={<Carrers />} />
        <Route path='/web-development' element={<WebDevelopment />} />
        <Route path='/consulting' element={<Consulting />} />
        <Route path='/software-development' element={<SoftwareDevelopment />} />
        <Route path='/tech-solutions' element={<TechSolutions />} />
        <Route path='/data-analytics' element={<DataAnalytics />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/Schedule' element={<Schedule />} />
        <Route path='/about' element={<About />} />
        <Route path='/our-vision' element={<VisionPage />} />
        <Route path='/application' element={<JobApplicationForm />} />
        <Route path='/products' element={<Products />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/TermsConditions' element={<TermsConditions />} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/RefundandCancellationPolicy' element={<RefundandCancellationPolicy/>} />
        <Route path='/ReturnPolicy' element={<ReturnPolicy />} />
        <Route path='/ShippingPolicy' element={<ShippingPolicy />} />
      </Routes>
    </>
  );
}

export default App;
