import React from 'react';
import Footer from './Footer/Footer';
import './Returnpolicy.css'; // Import the CSS file

const Return = () => {
  return (
    <div className="return-policy-container">
      <div className="return-content">
        <h1>Return Policy</h1><br />
        <p>
          We offer refund/exchange within the first 7 days from the date of your purchase. If 7 days have passed 
          since your purchase, you will not be offered a return, exchange, or refund of any kind. In order to become 
          eligible for a return or an exchange,<br />
          (i) the purchased item should be unused and in the same condition as you received it,<br />
          (ii) the item must have original packaging,<br />
          (iii) if the item that you purchased was on sale, then the item may not be eligible for a return/exchange.<br />
          Further, only such items are replaced by us (based on an exchange request) if such items are found defective or damaged.<br />
          You agree that there may be a certain category of products/items that are exempted from returns or refunds. Such categories of products 
          would be identified to you at the time of purchase.<br />
          For exchange/return accepted request(s) (as applicable), once your returned product/item is received and inspected 
          by us, we will send you an email to notify you about the receipt of the returned/exchanged product. Further, 
          if the same has been approved after the quality check at our end, your request (i.e., return/exchange) will 
          be processed in accordance with our policies.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Return;
