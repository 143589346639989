import React, { useState, useContext } from 'react';
import { CartContext } from './CartContext';
import './Cart.css';

const Cart = () => {
  const { cartItems, removeFromCart } = useContext(CartContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [emailError, setEmailError] = useState('');
  const [pinCodeError, setPinCodeError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');

  const handleRemoveItem = (itemId) => {
    removeFromCart(itemId);
  };

  const handleBuyNow = () => {
    if (isFormValid()) {
      alert('Buy Now functionality to be implemented!');
    }
  };

  const isFormValid = () => {
    return name && isEmailValid() && address && isPinCodeValid() && mobileNumber.length === 10;
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
      if (value.length < 10) {
        setMobileNumberError('Mobile number must be exactly 10 digits');
      } else {
        setMobileNumberError('');
      }
    }
  };

  const isEmailValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!isEmailValid()) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const isPinCodeValid = () => {
    return /^\d{6}$/.test(pinCode);
  };

  const handlePinCodeChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setPinCode(value);
      if (value.length > 0 && value.length < 6) {
        setPinCodeError('Pin code must be exactly 6 digits');
      } else {
        setPinCodeError('');
      }
    }
  };

  return (
    <div className="cart-container">
      <h2>Cart</h2>
      {!cartItems || cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <div className="cart-items">
          {cartItems.map((item) => {
            // Increase price of jeans by 950
            const itemPrice = item.name === 'Jeans' ? item.price + 950 : item.price;
            
            return (
              <div key={item.id} className="cart-item">
                <img src={item.imageUrl} alt={item.name} className="cart-item-image" />
                <div className="cart-item-details">
                  <h3>{item.name}</h3>
                  <p>Price: ₹{itemPrice}</p> {/* Updated to reflect new price */}
                  <p>Quantity: {item.quantity}</p>
                  <button onClick={() => handleRemoveItem(item.id)}>Remove</button>
                </div>
              </div>
            );
          })}
          <div className="form-group">
            <label>Name:</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="form-group">
            <label>Mobile Number:</label>
            <input
              type="text"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              maxLength="10"
              className={mobileNumberError ? 'input-error' : ''}
              placeholder={mobileNumberError || 'Enter your mobile number'}
            />
            {mobileNumberError && <p className="error">{mobileNumberError}</p>}
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="text"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email address"
            />
            {emailError && <p className="error">{emailError}</p>}
          </div>
          <div className="form-group">
            <label>Address:</label>
            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
          </div>
          <div className="form-group">
            <label>Pin Code:</label>
            <input
              type="text"
              value={pinCode}
              onChange={handlePinCodeChange}
              maxLength="6"
              placeholder="Enter 6-digit pin code"
            />
            {pinCodeError && <p className="error">{pinCodeError}</p>}
          </div>
          <button
            className="btn btn-primary"
            onClick={handleBuyNow}
            disabled={!isFormValid()}
          >
            Proceed to Buy Now
          </button>
        </div>
      )}
    </div>
  );
};

export default Cart;
