import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import Footer from './Footer/Footer';


const DataAnalytics = () => {
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: '',
    time: '',
    message: ''
  });

  const handleClose = () => {
    setShow(false);
    setSubmitted(false);
  };
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('service_0ibcerg', 'template_rwamlx8', formData, 'Y-xychfUUEkG6e5gj')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSubmitted(true);
        setTimeout(() => {
          setShow(false);
          setSubmitted(false);
        }, 2000);
      })
      .catch((error) => {
        console.log('FAILED...', error);
      });
  };

  return (
    <div className="container my-5" style={{ backgroundColor: '#4169e1' }}>
      <div className="row align-items-center">
        <div className="col-md-6" style={{paddingTop: '20px', paddingBottom: '20px'}}>
          <img src="https://k21academy.com/wp-content/uploads/2020/09/Data-Analyst.png" alt="Data Analytics" className="img-fluid rounded-lg" />
        </div>
        <div className="col-md-6">
          <h1 className="display-4 font-weight-bold mb-4"  style={{ color: 'white' }}><strong>Data Analytics Solutions</strong></h1>
          <p className="lead mb-4" style={{ color: 'white' }}>
            Unlock the power of your data with our comprehensive
            data analytics solutions. Our team of data experts
            will help you transform raw data into actionable
            insights that drive informed business decisions.
          </p>
          <p className="mb-4" style={{ color: 'white' }}>
            From custom business intelligence dashboards to
            advanced predictive analytics, we leverage the
            latest tools and technologies to deliver
            transformative data-driven solutions.
          </p>
          <div className="d-flex flex-column flex-md-row mb-4">
            <Button variant="btn btn-warning" size="lg" onClick={handleShow} style={{ color: 'black' , borderColor: 'black'}}>
              Get a Free Consultation
            </Button>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Business Intelligence</h4>
              <p className="card-text">
                Our business intelligence solutions provide
                data-driven insights and intuitive dashboards
                to help you make informed, strategic decisions.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Predictive Analytics</h4>
              <p className="card-text">
                Leverage the power of advanced analytics
                to identify trends, predict outcomes, and
                optimize your business processes.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Data Science Consulting</h4>
              <p className="card-text">
                Our data science experts provide strategic
                guidance and custom solutions to help you
                extract maximum value from your data.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get a Free Consultation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submitted && <Alert variant="success">Your consultation request has been submitted successfully!</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formMobile">
              <Form.Label> Mobile </Form.Label>
              <Form.Control type="tel" name="mobile" placeholder="Enter your mobile number" value={formData.mobile} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formDate">
              <Form.Label>Preferred Date</Form.Label>
              <Form.Control type="date" name="date" value={formData.date} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formTime">
              <Form.Label>Preferred Time</Form.Label>
              <Form.Control type="time" name="time" value={formData.time} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" placeholder="Enter your message" value={formData.message} onChange={handleChange} />
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

     <Footer />
    </div>
  );
};

export default DataAnalytics;