import React from 'react';
import { Link } from 'react-router-dom';

import logo1 from '../assets/images/logo1.png';
const Footer = () => {
  return (
    <footer className="text-center text-lg-start bg-body-tertiary text-muted">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span>Get connected with us on social networks:</span>
        </div>
        <div>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-google"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="#" className="me-4 text-reset">
            <i className="fab fa-github"></i>
          </a>
        </div>
      </section>

      <section>
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            <h6 className="text-uppercase fw-bold mb-4">
  <img src={logo1} alt="Haryak Technologies" style={{ width: '25px', height: 'auto', marginRight: '5px' }} />
  Haryak Technologies
</h6>

              <p>
                Haryak Technologies India Pvt. Ltd. delivers innovative software solutions, specializing in custom development, digital transformation, and IT consulting to drive efficiency and growth for businesses across diverse industries.
              </p>
            </div>

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Services</h6>
              <p>
                <Link to="/web-development" className="text-reset">Web Development</Link>
              </p>
              <p>
                <Link to="/consulting" className="text-reset">Consulting</Link>
              </p>
              <p>
                <Link to="/software-development" className="text-reset">Software Development</Link>
              </p>
              <p>
                <Link to="/tech-solutions" className="text-reset">Tech Solutions</Link>
              </p>
              <p>
                <Link to="/data-analytics" className="text-reset">Data Analytics</Link>
              </p>
              <p>
                <Link to="/products" className="text-reset">Products</Link>
              </p>
            </div>

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
              <p>
                <Link to="/" className="text-reset">Home</Link>
              </p>
              <p>
                <Link to="/Schedule" className="text-reset">Schedule</Link>
              </p>
              <p>
                <Link to="/careers" className="text-reset">Careers</Link>
              </p>
              <p>
                <Link to="/about" className="text-reset">About</Link>
              </p>
              <p>
                <Link to="/contact" className="text-reset">Help</Link>
              </p>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Policies</h6>
              <p>
                <Link to="/TermsConditions" className="text-reset">TermsConditions</Link>
              </p>
              <p>
                <Link to="/PrivacyPolicy" className="text-reset">PrivacyPolicy</Link>
              </p>
              <p>
                <Link to="/RefundandCancellationpolicy" className="text-reset">RefundandCancellation<br></br>policy</Link>
              </p>
              <p>
                <Link to="/ReturnPolicy" className="text-reset">ReturnPolicy</Link>
              </p>
              <p>
                <Link to="/ShippingPolicy" className="text-reset">ShippingPolicy</Link>
              </p>
            </div>
          
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p><i className="fas fa-home me-3"></i>Santhi Nagar, Chilakaluripet, Andhra Pradesh 522616</p>
              <p>
                <i className="fas fa-envelope me-3"></i>
                hr@haryak.com
              </p>
              <p><i className="fas fa-phone me-3"></i> +91 8500298398</p>
              <p><i className="fas fa-phone me-3"></i> +91 9739381011</p>
            </div>
          </div>
        </div>
      </section>

      <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className="text-reset fw-bold" href="/">Haryak Technologies India Private Limited
        </a>
      </div>
    </footer>
  );
}

export default Footer;
