import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Schedule.css';
import Footer from './Footer/Footer';
import userImage from './assets/images/logo1.png';

const MeetingScheduler = ({ onLinkGenerated = () => {} }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [duration, setDuration] = useState(10);
  const [meetingLink, setMeetingLink] = useState('');
  const [isScheduled, setIsScheduled] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [scheduledMeetings, setScheduledMeetings] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const generateGoogleMeetLink = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 10;
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return `https://meet.google.com/taq-zmdq-zya${result}`;
  };

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      setError("Please enter today's date or a future date.");
      e.target.classList.add('error');
    } else {
      setError('');
      e.target.classList.remove('error');
      setStartDate(selectedDate);
    }
  };

  const checkForConflicts = () => {
    const newStartDateTime = new Date(`${startDate.toISOString().split('T')[0]}T${startTime}`);
    for (const meeting of scheduledMeetings) {
      const meetingStart = new Date(meeting.startDateTime);
      const meetingEnd = new Date(meeting.endDateTime);
      if (newStartDateTime >= meetingStart && newStartDateTime < meetingEnd) {
        return true; // Conflict found
      }
    }
    return false; // No conflict
  };

  const handleSchedule = () => {
    setSuccessMessage('');

    if (!startTime || !startDate || !name || !email) {
      setError('Please fill in all fields.');
      return;
    }

    if (checkForConflicts()) {
      setError('This time slot is already booked. Please select another time.');
      return;
    }

    const generatedMeetingLink = generateGoogleMeetLink();
    setMeetingLink(generatedMeetingLink);
    setIsScheduled(true);
    setError('');

    const meetingDetails = {
      startDate: startDate.toDateString(),
      startTime: startTime,
      duration: `${duration} min`,
      meetingLink: generatedMeetingLink,
      name: name,
      email: email,
    };

    const startDateTime = new Date(`${startDate.toISOString().split('T')[0]}T${startTime}`);
    const endDateTime = new Date(startDateTime.getTime() + duration * 60000);

    setScheduledMeetings([
      ...scheduledMeetings,
      {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        meetingDetails: meetingDetails,
      },
    ]);

    console.log('Meeting Details:', meetingDetails);

    emailjs.send('service_w2ns26a', 'template_tsyfswp', meetingDetails, 'FIMAIbEiVlLIca8cv')
      .then((response) => {
        console.log('Email sent!', response.status, response.text);
        if (typeof onLinkGenerated === 'function') {
          onLinkGenerated(generatedMeetingLink);
        }
        setSuccessMessage('Appointment successfully booked!');

        // Clear the success message after 5 seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      })
      .catch((error) => {
        console.error('Email error:', error);
        setError(`There was an error sending the email. Error: ${error.message}`);
      });
  };

  const handleDelete = () => {
    setSuccessMessage('');

    if (!startTime || !startDate) {
      setError('Meeting details not found.');
      return;
    }

    const cancellationDetails = {
      startDate: startDate.toDateString(),
      startTime: startTime,
      meetingLink: meetingLink,
      name: name,
      email: email,
    };

    emailjs.send('service_w2ns26a', 'template_2d5x0lv', cancellationDetails, 'FIMAIbEiVlLIca8cv')
      .then((response) => {
        console.log('Cancellation email sent!', response.status, response.text);
        setMeetingLink('');
        setIsScheduled(false);
        const updatedMeetings = scheduledMeetings.filter(meeting =>
          meeting.startDateTime.getTime() !== new Date(`${startDate.toISOString().split('T')[0]}T${startTime}`).getTime()
        );
        setScheduledMeetings(updatedMeetings);
        setSuccessMessage('Appointment successfully cancelled!');
      })
      .catch((error) => {
        console.error('Cancellation email error:', error);
        setError('There was an issue sending the cancellation email. Please check your email configuration.');
      });
  };

  return (
    <>
      <div id="meeting-scheduler" className="meeting-scheduler">
        <div className="meeting-summary">
          <img src={userImage} alt="User" />
          <h2>Appointment with Haryak Technologies</h2>
          <p>Book your appointment with Haryak Technologies.</p>
          <p>Chilakaluripet, Palnadu District, Andhra Pradesh, India</p>
          <p>In this call, we'll discuss the best way to lead an army and rule a kingdom.</p>
          <p className="meeting-link">{meetingLink}</p>
        </div>
        <div className="booking-section">
          <h2>Meeting Scheduler</h2>
          {error && <p className="error-message">{error}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          <div className="form-row">
            <div className="form-group">
              <label>Starting Date:</label>
              <input type="date" value={startDate.toISOString().split('T')[0]} onChange={handleStartDateChange} />
            </div>
            <div className="form-group">
              <label>Starting Time:</label>
              <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
            </div>
          </div>
          <div className="form-group">
            <label>Name:</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="form-group">
            <label>Duration:</label>
            <select value={duration} onChange={(e) => setDuration(parseInt(e.target.value, 10))}>
              <option value={10}>10 min</option>
              <option value={20}>20 min</option>
              <option value={30}>30 min</option>
              <option value={40}>40 min</option>
              <option value={50}>50 min</option>
              <option value={60}>60 min</option>
            </select>
          </div>
          <button onClick={handleSchedule} className="schedule-button">
            Schedule
          </button>

          {isScheduled && (
            <div className="meeting-details">
              <h3>Meeting Details:</h3>
              <p>Start Date: {startDate.toDateString()}</p>
              <p>Start Time: {startTime}</p>
              <p>Name: {name}</p>
              <p>Email: {email}</p>
              <p>
                Meeting Link:{' '}
                <a href={meetingLink} target="_blank" rel="noopener noreferrer" className="meeting-link">
                  {meetingLink}
                </a>
              </p>
              <button onClick={handleDelete} className="delete-button">
                Delete Meeting
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MeetingScheduler;
