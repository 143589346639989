import React, { useState, useEffect, useContext } from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBriefcase, faInfoCircle, faEnvelope, faCogs, faCalendarAlt, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
//import { CartContext } from './CartContext';
import moment from 'moment-timezone';
import logoLarge from './assets/images/logo.png';
import logoSmall from './assets/images/logo1.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import './NavBar.css';

const TimeDisplay = ({ isSmallScreen }) => {
  const [indianTime, setIndianTime] = useState('');
  const [usaTime, setUsaTime] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const indianTimeNow = moment().tz('Asia/Kolkata').format('hh:mm:ss A');
      const usaTimeNow = moment().tz('America/New_York').format('hh:mm:ss A');
      setIndianTime(indianTimeNow);
      setUsaTime(usaTimeNow);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (isSmallScreen) return null;

  return (
    <div className="time-display">
      <img src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg" alt="India Flag" className="flag-icon"/>
      <span className="time-text">{indianTime}</span>
      <img src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg" alt="USA Flag" className="flag-icon" />
      <span className="time-text">{usaTime}</span>
    </div>
  );
};

const NavBar = () => {
  
  //const { cartCount } = useContext(CartContext);
  const [isSticky, setIsSticky] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 992);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Navbar expand="lg" className={`navbar ${isSticky ? 'sticky' : ''}`} sticky="top" expanded={expanded}>
      <Container fluid className="px-3">
        <Navbar.Brand as={Link} to="/" className="navbar-brand">
          <img
            src={isSmallScreen ? logoSmall : logoLarge}
            alt="Logo"
            className="d-inline-block align-top logo"
          />
          {isSmallScreen && (
            <div className="brand-text-container" style={{marginLeft:'1px', paddingTop:'4px'}}>
              <span className="brand-text-black">Haryak </span>
              <span className="brand-text-blue">Technologies</span>
            </div>
          )}
        </Navbar.Brand>

        <div className="time-container">
          <TimeDisplay isSmallScreen={isSmallScreen} />
        </div>

        <Navbar.Toggle aria-controls="navbarScroll" className="navbar-toggler" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Nav className="ml-auto" navbarScroll onClick={() => setExpanded(false)}>
            <Nav.Link as={Link} to="/" className="nav-link">
              <FontAwesomeIcon icon={faHome} /> <span>Home</span>
            </Nav.Link>
            <NavDropdown title={<span><FontAwesomeIcon icon={faCogs} /> Services</span>} id="servicesDropdown" className="nav-dropdown-link">
              <NavDropdown.Item as={Link} to="/web-development" className="nav-dropdown-item">
                Web Development
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/consulting" className="nav-dropdown-item">
                Consulting
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/software-development" className="nav-dropdown-item">
                Software Development
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/tech-solutions" className="nav-dropdown-item">
                Tech Solutions
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/data-analytics" className="nav-dropdown-item">
                Data Analytics
              </NavDropdown.Item>
              <NavDropdown.Divider />
              {/* <NavDropdown.Item as={Link} to="/products" className="nav-dropdown-item">
                Products
              </NavDropdown.Item> */}
            </NavDropdown>
            <Nav.Link as={Link} to="/careers" className="nav-link">
              <FontAwesomeIcon icon={faBriefcase} /> <span>Careers</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/about" className="nav-link">
              <FontAwesomeIcon icon={faInfoCircle} /> <span>About</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/schedule" className="nav-link">
              <FontAwesomeIcon icon={faCalendarAlt} /> <span>Schedule</span>
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/cart" className="nav-link position-relative">
              <FontAwesomeIcon icon={faShoppingCart} /> <span>Cart</span>
              {cartCount > 0 && (
                <span className={`badge rounded-circle bg-danger position-absolute ${isSmallScreen ? 'ms-1' : 'top-0 start-10 translate-middle'}`} style={{ fontSize: '0.55rem', padding: '0.35em 0.5em', paddingTop:'4px', paddingBottom:'4px', marginTop:'4px' }}>
                  {cartCount}
                </span>
              )}
            </Nav.Link> */}
            <Nav.Link as={Link} to="/contact" className="nav-link">
              <FontAwesomeIcon icon={faEnvelope} /> <span>Contact</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
