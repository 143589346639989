import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import Footer from './Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';


const About = () => {
  return (
    <div className="container my-5" style={{ backgroundColor: '#4169e1' }}>
      <div className="row align-items-center">
        <div className="col-md-6" style={{paddingTop: '20px', paddingBottom: '20px'}}>
          <img src="https://images.pexels.com/photos/8636598/pexels-photo-8636598.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load" alt="About" className="img-fluid rounded-lg" />
        </div>
        <div className="col-md-6">
          <h1 className="display-4 font-weight-bold mb-4" style={{ color: 'white' }}><strong>About Our Haryak Technologies</strong></h1>
          <p className="lead mb-4" style={{ color: 'white' }}>
            We are a team of passionate software engineers and designers, dedicated to
            creating innovative solutions that empower businesses to thrive in the digital age.
          </p>
          <p className="mb-4" style={{ color: 'white' }}>
            Our company was founded with the vision of providing cutting-edge software
            products and services that address the unique challenges faced by our clients.
            With a strong focus on user-centric design and agile development practices,
            we strive to deliver exceptional value and drive sustainable growth for
            the businesses we serve.
          </p>
          <div className="d-flex flex-column flex-md-row mb-4">
            
            <Link to="/our-vision" className="btn btn-warning btn-1g" style={{color:'black', borderColor:'black'}}>
              Our Vision
            </Link>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <h1 style={{ textAlign: "center", color: 'white' }}>Our Clients</h1>
      <div className="container1" style={{ backgroundColor: 'white', padding: '20px' }}>
        <div className="logo">
          <img src="https://logospng.org/download/google/logo-google-1024.png" alt="logo" />
        </div>
        <div className="logo">
          <img src="https://www.pngmart.com/files/23/Ibm-Logo-PNG-HD.png" alt="logo" />
        </div>
        <div className="logo">
          <img src="https://th.bing.com/th/id/R.805035552880341f16fe221f218058b2?rik=Q3l8At8q4jE8eA&riu=http%3a%2f%2fassets.stickpng.com%2fimages%2f61f7d14967553f0004c53e7d.png&ehk=ltXwvjWnE6pw9sV7wGJRW8pfKy8oqqSfVXYR%2fs0ksxU%3d&risl=&pid=ImgRaw&r=0" alt="logo" />
        </div>
        <div className="logo">
          <img src="https://1.bp.blogspot.com/-mrDu3arMFkc/XcapyvMfUKI/AAAAAAAABI8/rik0le3aW1k-M2AWTnE_JD2Xm1cqZORGACLcBGAsYHQ/s1600/Logo%2BRedDoorz.png" alt="logo" />
        </div>
        <div className="logo">
          <img src="https://static.wixstatic.com/media/cd0dcb_42f34362c81e4eb4b05dfff0505c2fde~mv2.png/v1/fill/w_407,h_226,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/kurlon-vector-logo_edited.png" alt="logo" />
        </div>
        <div className="logo">
          <img src="https://companieslogo.com/img/orig/WIT_BIG-0de2dc21.png?t=1648406781" alt="logo" />
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default About;
