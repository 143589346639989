import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from 'emailjs-com';
import { Alert } from 'react-bootstrap';

import './contact.css';
import Footer from './Footer/Footer';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_0ibcerg', 'template_rwamlx8', formData, 'Y-xychfUUEkG6e5gj')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
        }, 2000);
      })
      .catch((error) => {
        console.log('FAILED...', error);
      });
  };

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-md-6">
          <h1 className="display-4 font-weight-bold mb-4">Get in Touch</h1>
          <p className="lead mb-4">
            Have a question or interested in our services? Fill out the form
            below and one of our representatives will get back to you as
            soon as possible.
          </p>
          <form className="bg-light p-4 rounded shadow-sm" onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label font-weight-bold">Name</label>
              <input type="text" className="form-control" id="name" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email" className="form-label font-weight-bold">Email</label>
              <input type="email" className="form-control" id="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="message" className="form-label font-weight-bold">Message</label>
              <textarea className="form-control" id="message" name="message" rows="5" placeholder="Enter your message" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit" className="btn btn-primary btn-lg w-100 mt-3">Submit</button>
            {submitted && <Alert variant="success" className="mt-3">Your message has been sent successfully!</Alert>}
          </form>
        </div>
        <div className="col-md-6">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Contact Information</h4>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <i className="fas fa-map-marker-alt" style={{ marginRight: '0.5rem' }}></i>
                  Santhi Nagar, Chilakaluripet, Andhra Pradesh 522616
                </li>
                <li className="mb-3">
                  <i className="fas fa-phone-alt me-3"></i>
                  +91 8500298398
                </li>
                <li className="mb-3">
                  <i className="fas fa-phone-alt me-3"></i>
                  +91 9739381011
                </li>
                <li className="mb-3">
                  <i className="fas fa-envelope me-3"></i>
                  hr@haryak.com
                </li>
              </ul>
              <div className="social-media d-flex flex-column align-items-start">
                <h5 className="mb-3">Follow Us:</h5>
                <div className="d-flex justify-content-start">
                  <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="me-4 mb-3">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/facebook/facebook-original.svg" alt="Facebook" style={{ width: '24px', height: '24px' }} />
                  </a>
                  <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="me-4 mb-3">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/twitter/twitter-original.svg" alt="Twitter" style={{ width: '24px', height: '24px' }} />
                  </a>
                  <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="me-4 mb-3">
                    <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linkedin/linkedin-original.svg" alt="LinkedIn" style={{ width: '24px', height: '24px' }} />
                  </a>
                </div>
              </div>
              <div className="map-container mt-4" style={{ backgroundColor: '#f8f9fa', padding: '20px', borderRadius: '5px' }}>
                <div className="map-wrapper" style={{ position: 'relative', overflow: 'hidden', paddingTop: '56.25%' }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.5561210098176!2d80.1575989!3d16.088507700000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4a6337b1dbf91d%3A0x1b400dcd5e894b67!2sHaryak%20Technologies%20India%20Private%20Limited!5e0!3m2!1sen!2sin!4v1725516729315!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    style={{ border: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Contact;
