import React from 'react';
import { Carousel, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer/Footer';
import './VisionPage.css'; // Make sure this is the correct path to your CSS file

const VisionPage = () => {
  return (
    <div className="vision-page">
      {/* Hero Section */}
      <div className="hero-section text-center text-white">
        <div className="hero-content">
          <h1>Welcome to Haryak Technologies India Private Limited</h1>
          <p>Innovative Solutions for a Digital World</p>
          <Button variant="primary" size="lg" href="#vision">Discover More</Button>
        </div>
      </div>

      <header className="navigation">
        <nav>
          <ul>
            <li><a href="#vision">Our Vision</a></li>
            <li><a href="#why-choose-us">Why Choose Us</a></li>
            <li><a href="#testimonials">Testimonials</a></li>
          </ul>
        </nav>
      </header>

      <main>
  {/* Vision Section */}
  <section id="vision" className="vision-section parallax" style={{ marginTop: '80px' }}>
    {/* Add marginTop to create space below navigation */}
    <Container className="text-center">
      <h1 className="section-title">Our Vision</h1>
      <p className="lead">
        At Haryak Technologies India Pvt. Ltd., we envision a world where technology seamlessly integrates with everyday life, driving innovation and growth for businesses globally.
      </p>
      <p>
        We are committed to delivering cutting-edge solutions that empower our clients to achieve their goals and stay ahead in a rapidly evolving digital landscape.
      </p>
    </Container>
  </section>

  {/* Why Choose Us Section */}
  <section id="why-choose-us" className="why-choose-us-section parallax" style={{ marginTop: '80px' }}>
    {/* Add marginTop to create space below navigation */}
    <Container>
      <h1 className="section-title text-center">Why Choose Us</h1>
      <Row className="text-center">
        <Col md={4}>
          <div className="feature">
            <h5>Innovative Solutions</h5>
            <p>Our team is dedicated to creating cutting-edge solutions that drive your business forward.</p>
          </div>
        </Col>
        <Col md={4}>
          <div className="feature">
            <h5>Experienced Professionals</h5>
            <p>Our experts bring a wealth of industry knowledge and technical expertise to every project.</p>
          </div>
        </Col>
        <Col md={4}>
          <div className="feature">
            <h5>Tailored Approach</h5>
            <p>We understand your unique needs and deliver customized solutions to ensure your success.</p>
          </div>
        </Col>
      </Row>
    </Container>
  </section>

  {/* Testimonials Section */}
  <section id="testimonials" className="testimonials-section" style={{ marginTop: '80px' }}>
    {/* Add marginTop to create space below navigation */}
    <Container>
      <h1 className="section-title text-center">Testimonials</h1>
      <Carousel className="w-75 mx-auto">
        <Carousel.Item>
          <p>"Haryak Technologies transformed our business operations. Their innovative solutions have significantly improved our efficiency."</p>
          <h5>- Mark Anthony</h5>
        </Carousel.Item>
        <Carousel.Item>
          <p>"The team at Haryak Technologies is exceptional. They understood our needs and delivered beyond our expectations."</p>
          <h5>- Agarwal</h5>
        </Carousel.Item>
        <Carousel.Item>
          <p>"Professional, reliable, and cutting-edge. Haryak Technologies is our go-to partner for all tech solutions."</p>
          <h5>- Rajan Singh</h5>
        </Carousel.Item>
      </Carousel>
    </Container>
  </section>
</main>

      <Footer />
    </div>
  );
};

export default VisionPage;
