import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import Footer from './Footer/Footer';
import { useNavigate } from 'react-router-dom';
import './Carrers.css'; // Ensure this path is correct

const Careers = () => {
  const [currentCategory, setCurrentCategory] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [hasShownUrgentModal, setHasShownUrgentModal] = useState(false);
  const navigate = useNavigate();

  const jobs = [
    {
      id: 'HAR_INF_501',
      title: 'Software Developer',
      company: 'Infosys',
      location: 'Bengaluru',
      description: 'Develop and maintain software applications for VISA clients.',
      skills: ['Java 8', 'SpringBoot', 'Selenium'],
      experience: '5+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'infosys',
    },
    {
      id: 'HAR_INF_502',
      title: 'Java Developer',
      company: 'Infosys',
      location: 'Bengaluru',
      description: 'Develop and maintain Java-based applications. Work with automation tools and big data technologies.',
      skills: ['Java', 'Kafka', 'BBD'],
      experience: '5+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'infosys',
      additionalInfo: 'Immediate Joiners Required',
    },
    {
      id: 'HAR_WAF_503',
      title: 'Java Backend Developer',
      company: 'Wayfair',
      location: 'Remote',
      description: 'Experience as a backend, full lifecycle software engineer with deep knowledge of modern programming languages (e.g., Java), GCP or other cloud services, and Kubernetes. Proficient in system design at scale, relational database schema design, troubleshooting, and effective communication with engineers.',
      skills: ['Java', 'GCP', 'Kubernetes'],
      experience: '5+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'wayfair',
      additionalInfo: 'Immediate Joiners Required',
    },
    {
      id: 'HAR_WAF_504',
      title: 'Senior Software Engineer',
      company: 'Wayfair',
      location: 'Remote',
      description: 'Expertise in scalable cloud solutions, front-end development, microservice architectures, and handling high-volume messaging. Familiarity with GCP, React, Angular, and modern tools.',
      skills: [
        'GCP', 'Front-end Development', 'Microservice Architectures', 'Async Messaging',
        'Relational and NoSQL Data Stores', 'Kafka', 'Kubernetes', 'Containerization',
        'Java Microservices', 'GraphQL', 'NextJS', 'React'
      ],
      experience: '10+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'wayfair',
    },
    {
      id: 'HAR_WAF_505',
      title: 'Engineering Manager',
      company: 'Wayfair',
      location: 'Remote',
      description: `Leadership and Team Management: 5+ years in engineering, including at least 4 years leading high-performance teams. Expertise in making architectural and design decisions for large-scale platforms while balancing time-to-market and flexibility.
      Growth and Performance: Proven track record in managing and enabling team growth and performance, and hiring and developing engineering teams.
      Architectural Experience: Hands-on experience designing and building high-scale products with outstanding user experience. Skilled in managing and scaling microservices in cloud-native architectures.
      Collaboration and Communication: Strong capability to communicate and collaborate across organizations, influencing decisions with and without direct authority. Effective in fast-paced, agile environments.
      Analytical Skills: Integrates technical and business requirements with a focus on customer-facing experiences and capabilities.`,
      skills: [
        'Leadership', 'Team Management', 'Architectural Design', 'Microservices',
        'Cloud Platforms', 'Scalability'
      ],
      experience: '10+ years',
      education: 'Bachelor of Science in Computer Science',
      category: 'wayfair',
    },
    {
      id: 'HAR_EXL_506',
      title: 'Senior Java Backend Developer',
      company: 'EXL',
      location: 'Remote Pune/Hyderabad',
      description: `Solve complex software engineering problems, learn new skills, and build expertise in areas of your interest. Design, code, test, debug, optimize, and document complex web/middleware applications using other technologies. Develop all layers of enterprise applications and get involved in interesting DevOps activities to have end-to-end involvement. Provide direction and support to juniors to help their understanding of more complex software engineering. Perform other duties as assigned or apparent. Participate in Scrum with activities such as assisting QAs, perform code reviews, unit testing, research spikes, support company’s architectural standards, contribute in creating new standards supporting continuous improvement, etc.`,
      skills: [
        'Java 8/J2EE', 'Spring', 'Spring Boot', 'Microservices', 'Hibernate', 'REST', 'Docker',
        'GCP'
      ],
      experience: '5+ years',
      education: 'Bachelor’s in Engineering - Computer Science / IT',
      category: 'exl',
    },
    {
      id: 'HAR_EXL_507',
      title: 'Full Stack Java Engineer',
      company: 'EXL',
      location: 'Pune, Hyderabad, Hybrid',
      description: `Design, develop, test, and deploy high-quality, scalable web applications using Java and related technologies. Develop and maintain front-end interfaces using modern JavaScript frameworks. Implement RESTful APIs and integrate with various third-party services and databases. Collaborate with architects and other engineers to design system architecture and ensure seamless integration of front-end and back-end components. Write clean, maintainable code and follow best practices. Perform code reviews, implement automated testing, and ensure software quality. Design and optimize database schemas and queries. Work with relational and NoSQL databases. Communicate technical concepts effectively and participate in agile development processes. Debug and resolve technical issues and provide support for existing applications. Continuously monitor and improve application performance and user experience.`,
      experience: '5-7 years',
      education: 'Bachelor of Science in Computer Science',
      skills: [
        'Java', 'JavaScript', 'Angular', 'CI/CD', 'RESTful APIs',
        'MySQL', 'PostgreSQL', 'MongoDB', 'Docker', 'Kubernetes',
        'Google Cloud'
      ],
      additionalSkills: [
        'Knowledge of Java collection framework',
        'Expertise in Spring and Hibernate frameworks',
        'Experience with containerization and orchestration tools',
        'Understanding of cloud platforms',
        'Strong problem-solving skills',
        'Excellent communication and teamwork skills'
      ],
      category: 'exl',
    },
    {
      id: 'HAR_EXL_508',
      title: 'Scrum Master',
      company: 'EXL',
      location: 'Pune, Hyderabad, Gurgram, Hybrid',
      description: `Design, develop, test, and deploy high-quality, scalable web applications using Java and related technologies. Develop and maintain front-end interfaces using modern JavaScript frameworks. Implement RESTful APIs and integrate with various third-party services and databases. Collaborate on system architecture and ensure seamless integration of front-end and back-end components. Write clean, maintainable code and follow best practices. Perform code reviews, implement automated testing, and ensure software quality. Design and optimize database schemas and queries. Work with relational and NoSQL databases. Communicate technical concepts effectively and participate in agile development processes. Debug and resolve technical issues and provide support for existing applications. Continuously monitor and improve application performance and user experience.`,
      experience: '8-12 years',
      education: 'Bachelor of Science in Computer Science',
      skills: [
        'Java', 'JavaScript', 'Angular', 'CI/CD', 'RESTful APIs',
        'MySQL', 'PostgreSQL', 'MongoDB', 'Docker', 'Kubernetes',
        'Google Cloud'
      ],
      additionalSkills: [
        'Proven experience as a Scrum Master in software development environments',
        'Deep understanding of Agile methodologies and principles',
        'Strong facilitation, coaching, and mentoring skills',
        'Ability to manage multiple teams and projects effectively',
        'Excellent problem-solving and conflict resolution skills'
      ],
      category: 'exl',
    },
    {
      id: 'HAR_EXL_509',
      title: 'Data Analyst',
      company: 'EXL',
      location: 'Remote',
      description: `Act as a liaison between the technical and business teams. Connect with client SMEs to understand functionality and data. Perform data profiling to assess data quality and standardize reference and master data across systems. Work on data mapping, data lineage documentation, data models, and design documents to help stakeholders understand data mappings and transformations.`,
      skills: [
        'Snowflake', 'SQL', 'Data Mapping', 'Data Transformations', 'Data Profiling',
        'Business Requirement Gathering', 'Source-to-Target Mappings (STTM)'
      ],
      experience: '4-7 years',
      education: 'Bachelor of Science in Computer Science',
      category: 'exl',
      role: 'Data Analyst',
      numberOfOpenPositions: 2,
      mustHaveQualifications: {
        experience: '4 to 7 years of work experience in large-scale data applications focusing on Business Intelligence (BI) and Data Analytics.',
        skills: [
          'Proven experience with Snowflake, SQL, and data mapping.',
          'Strong data profiling and data transformation skills.',
          'Excellent verbal and written communication skills.'
        ],
        domainExpertise: 'Experience working with clients in the Insurance domain, specifically Property and Casualty Insurance (P&C).',
        personalAttributes: [
          'Self-starter with the ability to integrate quickly into the team.',
          'Ability to work independently towards team goals.'
        ]
      },
      additionalSkills: [
        'Exposure to data consolidation, transformation, and standardization from various systems.'
      ],
      niceToHave: {
        domainKnowledge: 'Familiarity with the Insurance domain.',
        technicalSkills: 'Additional knowledge in SQL.'
      },
      roleAndResponsibilities: {
        liaisonRole: [
          'Act as a liaison between the technical and business teams.',
          'Connect with client SMEs to understand functionality and data requirements.'
        ],
        dataProfiling: [
          'Perform data profiling to assess the quality of data and critical data elements.',
          'Standardize reference and master data across systems.'
        ],
        documentation: [
          'Develop data mapping, data lineage documentation, data models, and design documents.',
          'Ensure stakeholders understand data mappings and transformations.'
        ]
      }
    },
    {
      id: 'HAR_SAG_510',
      title: 'Tech Delivery Lead (Java Fullstack Developer)',
      company: 'Confidential',
      location: 'Hyderabad (Hybrid)',
      description: `Lead a team of Java developers while engaging in architectural decisions, system design, and code reviews to ensure best engineering practices. Manage multiple projects, oversee timelines, and foster a culture of continuous improvement.`,
      skills: [
        'Java (11-17+)',
        'Spring',
        'Hibernate',
        'API Development',
        'Microservices Architecture',
        'System Integrations',
        'AWS/Azure/GCP',
        'Docker',
        'Kubernetes',
        'MySQL/PostgreSQL',
        'MongoDB',
      ],
      experience: '8-10 years',
      education: 'Bachelor of Science in Computer Science or related field',
      category: 'urgent',
      imageUrl: 'https://thumbs.dreamstime.com/b/urgent-red-grunge-round-vintage-stamp-rubber-88514659.jpg',
      keySkills: {
        TechnicalLeadership: [
          'Lead a team of Java developers, providing both hands-on technical guidance and code-level expertise.',
          'Engage in architectural decisions, system design, and code reviews to ensure best engineering practices are followed.',
          'Collaborate with stakeholders to define technical roadmaps and ensure alignment with project objectives.',
        ],
        DeliveryManagement: [
          'Establish and manage the delivery process, ensuring timely, high-quality releases that meet business objectives.',
          'Oversee project timelines, resource allocation, risk management, and mitigation plans.',
          'Manage multiple projects and ensure seamless collaboration across cross-functional teams, such as Product, QA, and DevOps.',
        ],
        EngineeringExcellence: [
          'Drive the implementation of software engineering principles, including design patterns, code reviews, automated testing, and CI/CD pipelines.',
          'Foster a culture of continuous improvement, innovation, and technical excellence within the team.',
          'Advocate for agile methodologies (Scrum, Kanban) and ensure proper implementation of agile processes.',
        ],
        TeamDevelopment: [
          'Mentor and coach software engineers on technical skills, architecture, and design principles.',
          'Conduct performance reviews, and identify areas of growth for team members.',
          'Provide guidance on improving coding standards, unit testing, and development workflows.',
        ],
        TechnicalExpertise: [
          'Proven experience in Java (11 - 17+) and related frameworks (e.g., Spring, Hibernate).',
          'Strong background in API development, microservices architecture, and system integrations.',
          'Knowledge of relational databases (e.g., MySQL, PostgreSQL) and NoSQL databases (e.g., MongoDB).',
          'Familiarity with cloud environments (AWS, Azure, GCP) and containerization technologies (Docker, Kubernetes).',
        ],
        ExperienceInDelivery: [
          'Previous experience managing software delivery teams, ideally in an Agile environment.',
          'Demonstrated experience in establishing engineering processes (CI/CD, automated testing, version control).',
          'Ability to manage large, complex technical projects and lead teams to meet tight deadlines.',
          'Able to review code and pitch in as required.',
        ],
        LeadershipAndMentorship: [
          'Strong people management skills with a focus on mentorship, career development, and team empowerment.',
          'Excellent communication skills and ability to work with cross-functional teams.',
        ],
        AdditionalResponsibilities: [
          'Responsible for designing, implementing, and maintaining Java applications with a focus on database-in-memory, data grid, and caching technologies.',
          'Implement and optimize data storage solutions, including database in-memory, and caching mechanisms.',
          'Work on data grid technologies to improve data distribution and access times in a distributed environment.',
          'Investigate and resolve performance bottlenecks, memory issues, and scalability challenges.',
          'Troubleshoot and debug issues in production environments.',
          'Knowledge in Java multithreading, concurrency, and memory management.',
        ],
      },
    }

  ];

  useEffect(() => {
    const urgentJob = jobs.find(job => job.category === 'urgent');
    if (urgentJob && !hasShownUrgentModal) {
      setSelectedJob(urgentJob);
      setShowModal(true);
      setHasShownUrgentModal(true);
    }
  }, [hasShownUrgentModal, jobs]);

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  const openModal = (job) => {
    setSelectedJob(job);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedJob(null);
  };

  const handleApplyNow = () => {
    if (selectedJob) {
      navigate('/application', {
        state: { jobId: selectedJob.id, jobTitle: selectedJob.title }
      });
    }
  };

  const filteredJobs = currentCategory === 'all'
    ? jobs
    : jobs.filter(job => job.category === currentCategory.toLowerCase());

  return (
    <div className="careers-page">
      <div className="container my-5">
        <h1 className="careers-title">Open Positions</h1>
        <div className="tabs mb-4 text-center">
          <button className="btn btn-link" onClick={() => handleCategoryChange('all')}>All Positions</button>
          <button className="btn btn-link" onClick={() => handleCategoryChange('infosys')}>Infosys</button>
          <button className="btn btn-link" onClick={() => handleCategoryChange('wayfair')}>Wayfair</button>
          <button className="btn btn-link" onClick={() => handleCategoryChange('exl')}>EXL</button>
          <button className="btn btn-link" onClick={() => handleCategoryChange('corpus')}>Corpus</button>
          <button className="btn btn-link" onClick={() => handleCategoryChange('urgent')}>Urgent</button>
        </div>

        {filteredJobs.length === 0 ? (
          <p className="text-center text-black">Currently, there are no jobs available in this category. Please check back later!</p>
        ) : (
          <div className="row">
            {filteredJobs.map((job) => (
              <div key={job.id} className="col-md-4 mb-4">
                <div className="card h-100 shadow-sm">
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">{job.title}</h5>
                    <p className="card-text">
                      <strong>Job ID:</strong> {job.id}<br />
                      <strong>Experience:</strong> {job.experience}<br />
                      <strong>Skills:</strong> {job.skills.join(', ')}<br />
                      <strong>Company:</strong> {job.company}
                    </p>
                    <Button variant="primary" onClick={() => openModal(job)}>Show Full Details</Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <h5>{selectedJob?.category === 'urgent' ? 'Urgent Requirement' : selectedJob?.title}</h5>
            {selectedJob?.category === 'urgent' && (
              <img src={selectedJob?.imageUrl} alt="Urgent" style={{ width: '50px', height: '50px', marginLeft: '10px' }} />
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Company:</strong> {selectedJob?.company}</p>
          <p><strong>Location:</strong> {selectedJob?.location}</p>
          <p><strong>Description:</strong> {selectedJob?.description}</p>
          <p><strong>Experience Required:</strong> {selectedJob?.experience}</p>
          <p><strong>Education:</strong> {selectedJob?.education}</p>
          <p><strong>Skills:</strong> {selectedJob?.skills.join(', ')}</p>

          {selectedJob?.category === 'urgent' && (
            <div>
              <h5><strong>Key Skills and Responsibilities:</strong></h5>
              {selectedJob?.keySkills && Object.entries(selectedJob.keySkills).map(([key, value]) => (
                <div key={key}>
                  <h6><strong>{key.replace(/([A-Z])/g, ' $1')}</strong></h6>
                  <ul>
                    {value.map((item, index) => (
                      <li key={index} style={{ listStyleType: 'none' }}>
                        <span style={{ fontSize: '20px' }}>•</span> {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Close</Button>
          <Button variant="primary" onClick={handleApplyNow}>Apply Now</Button>
        </Modal.Footer>
      </Modal>
      
      <Footer />
    </div>
  );
};

export default Careers;
