import React from 'react';
import Footer from './Footer/Footer';
import './privacypolicy.css'; // Import the CSS file

const Privacy = () => {
  return (
    <div className="privacy-container">
      <div className="content-container">
        <h1 align="center">Privacy Policy</h1>
        <p>
          <strong>1. Scope and Acceptance</strong><br />
          Applies to <strong>"HARYAK TECHNOLOGY INDIA PRIVATE LIMITED"</strong> and affiliates.<br />
          Covers data processing on <strong>www.haryak.com</strong>.<br />
          Personal data is primarily stored and processed in India.<br />
          Using the Platform indicates agreement to the Privacy Policy and Indian laws.<br /><br />
          
          <strong>2. Data Collection</strong><br />
          Information collected includes name, address, phone number, email, and sensitive data (e.g., payment info) with consent.<br />
          Users can choose not to provide certain data by not using specific features.<br />
          Behavior, preferences, and transactions are tracked.<br /><br />
          
          <strong>3. Third-Party Data</strong><br />
          Data collected by third parties is governed by their privacy policies.<br />
          <strong>Haryak Technology</strong> is not responsible for third-party practices.<br /><br />
          
          <strong>4. Data Usage</strong><br />
          Personal data is used for providing services, marketing (with opt-out option), and other activities like fraud detection and customer support.<br /><br />
          
          <strong>5. Data Sharing</strong><br />
          Shared internally within Haryak entities and with third parties (e.g., service providers) for service provision and legal compliance.<br />
          Disclosed to law enforcement or government agencies as required by law.<br /><br />
          
          <strong>6. Security</strong><br />
          Reasonable security practices are adopted, but data transmission over the internet carries inherent risks.<br />
          Users are responsible for protecting their login information.<br /><br />
          
          <strong>7. Data Deletion and Retention</strong><br />
          Accounts can be deleted, resulting in loss of related data.<br />
          Data is retained as required by law or for legitimate purposes and may be anonymized for research.<br /><br />
          
          <strong>8. User Rights</strong><br />
          Users can access, update, and rectify their personal data through Platform functionalities.<br /><br />
          
          <strong>9. Consent</strong><br />
          By using the Platform, users consent to data collection, use, and processing.<br />
          Consent can be withdrawn by contacting the Grievance Officer, but this may affect service provision.<br /><br />
          
          <strong>10. Policy Changes</strong><br />
          The Privacy Policy may be updated; users should review it periodically.<br />
          Significant changes will be notified as required by law.<br /><br />
          
          <strong>Grievance Officer</strong><br />
          Insert Name of the Office:<br />
          Designation:<br />
          Insert Name and Address of the Company:<br />
          Contact us:<br />
          Phone: Time: Monday - Friday (9:00 - 18:00)
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
