import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { CartContext } from './CartContext';
import './Products.css';
import Footer from './Footer/Footer';

const products = [
  { id: 1, name: 'Laptop', price: 70000, description: 'High-performance laptop', imageUrl: 'https://th.bing.com/th/id/OIP.XvIAtRojdgGOQiB-89B8CQHaHa?pid=ImgDet&w=192&h=192&c=7' },
  { id: 2, name: 'Smartphone', price: 25000, description: 'Latest model smartphone', imageUrl: 'https://image.made-in-china.com/2f0j00AlmbqUpPleoc/2-SIM-Card-I14-PRO-Max-Smartphone-5g-4G-6-8inch-Game-Mobile-Phone.jpg' },
  { id: 3, name: 'Headphones', price: 2500, description: 'Noise-cancelling headphones', imageUrl: 'https://th.bing.com/th/id/R.1341ea15bdd051f95c556dad81144e53?rik=L6P9hbw93jno7w&riu=http%3a%2f%2fwww.bhphotovideo.com%2fimages%2fimages2500x2500%2fbeats_by_dr_dre_900_00183_01_studio_wireless_over_ear_headphone_1037578.jpg&ehk=Gvcvd4F3e5KImn%2bDtTXuzfLaCL5syVO0QX596b8x35M%3d&risl=&pid=ImgRaw&r=0' },
  { id: 4, name: 'Monitor', price: 20000, description: '4K UHD monitor', imageUrl: 'https://th.bing.com/th/id/OIP.VxGFnMNBV9oXzkZiekjI4gHaHa?w=500&h=500&rs=1&pid=ImgDetMain' },
  { id: 5, name: 'Keyboard', price: 2000, description: 'Mechanical keyboard', imageUrl: 'https://m.media-amazon.com/images/I/71-mdutc7JL.jpg' },
  { id: 6, name: 'Jeans', price: 1000, description: 'Mid-Rise Wow Super Skinny Jeans for Women', imageUrl: 'https://oldnavy.gap.com/webcontent/0027/246/640/cn27246640.jpg' },
  { id: 7, name: 'AC', price: 50000, description: 'LG AI Convertible 6-in-1 Cooling 2024 Model 1.5 Ton 5 Star Split Inverter 4 Way Swing, HD Filter with Anti-Virus Protection AC with VIRAAT Mode & ADC Sensor AC - White  (TS-Q19YNZE, Copper Condenser)', imageUrl: 'https://th.bing.com/th/id/OIP.7Mz1IBV72FtXdIC0Y6SBRQAAAA?w=285&h=190&c=7&r=0&o=5&pid=1.7' },
  { id: 8, name: 'Shoes', price: 3000, description: 'New Latest Affordable Range of Trendy Sports Running Shoes For Men  (Green)', imageUrl: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/a/n/9/6-zigzag-black-shoes-for-men-arivo-white-black-original-imaguyw4vwytymzf.jpeg?q=70' },
  { id: 9, name: 'Shirt', price: 1000, description: 'Men Slim Fit Solid Spread Collar Casual Shirt', imageUrl: 'https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/z/i/x/l-191-combraided-original-imahyap8eat7k7ac.jpeg?q=70&crop=false' },
];

const ProductPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { addToCart } = useContext(CartContext);

  const handleSearch = (event) => setSearchTerm(event.target.value);

  const handleImageClick = (product) => setSelectedProduct(product);

  const handleCloseModal = () => setSelectedProduct(null);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddToCart = () => {
    if (selectedProduct) {
      addToCart(selectedProduct);
      setSelectedProduct(null); // Close the modal after adding to cart
    }
  };

  return (
    <Container>
      <h1 className="my-4" style={{ textAlign: 'center' }}>Product Page</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search for products..."
          value={searchTerm}
          onChange={handleSearch}
          className="form-control"
        />
      </div>
      <Row>
        {filteredProducts.map((product) => (
          <Col key={product.id} sm={6} md={4} lg={3} className="mb-4">
            <Card className="product-card">
              <Card.Img
                variant="top"
                src={product.imageUrl}
                onClick={() => handleImageClick(product)}
                className="product-image"
                style={{ width: '200px', height: '200px', objectFit: 'cover' }}
              />
              <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                <Card.Text className="price-text">₹{product.price}</Card.Text>
                <Button variant="primary" onClick={() => handleImageClick(product)}>
                  View Details
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {selectedProduct && (
        <Modal show onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedProduct.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src={selectedProduct.imageUrl}
              alt={selectedProduct.name}
              className="img-fluid mb-3"
              style={{ width: '200px', height: '200px', objectFit: 'cover' }}
            />
            <p>{selectedProduct.description}</p>
            <p><strong>Price: ₹{selectedProduct.price}</strong></p>
            </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddToCart}>
              Add to Cart
            </Button>
          </Modal.Footer>
        </Modal>
      )}
       <Footer />
    </Container>
   
  );
};

export default ProductPage;
