import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import Footer from './Footer/Footer';


const Consulting = () => {
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    date: '',
    time: '',
    message: '',
    file: null
  });

  const handleClose = () => {
    setShow(false);
    setSubmitted(false);
  };
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;

    emailjs.sendForm('service_0ibcerg', 'template_rwamlx8', form, 'Y-xychfUUEkG6e5gj')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSubmitted(true);
        setTimeout(() => {
          setShow(false);
          setSubmitted(false);
        }, 2000);
      })
      .catch((error) => {
        console.log('FAILED...', error);
      });
  };

  return (
    <div className="container my-5" style={{ backgroundColor: '#4169e1' }}>
      <div className="row align-items-center">
        <div className="col-md-6" style={{paddingTop: '20px', paddingBottom: '20px'}}>
          <img src="https://tse4.mm.bing.net/th/id/OIP.Dl60K4Qpqtw9Nfz905t6OwHaE0?rs=1&pid=ImgDetMain" alt="Consulting" className="img-fluid rounded-lg" />
        </div>
        <div className="col-md-6">
          <h1 className="display-4 font-weight-bold mb-4" style={{ color: 'white' }}><strong>Consulting Services</strong></h1>
          <p className="lead mb-4" style={{ color: 'white' }}>
            Our team of experienced consultants are dedicated to helping
            businesses like yours navigate the complex and ever-changing
            technology landscape.
          </p>
          <p className="mb-4" style={{ color: 'white' }}>
            From strategic IT planning to technology implementation
            and support, we provide a comprehensive suite of consulting
            services tailored to your specific needs and goals.
          </p>
          <div className="d-flex flex-column flex-md-row mb-4">
            <Button variant="btn btn-warning" size="lg" onClick={handleShow} style={{ color: 'black' , borderColor: 'black'}}>
              Schedule a Consultation
            </Button>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">IT Strategy Consulting</h4>
              <p className="card-text">
                Our IT strategy consultants work closely with you to
                develop a roadmap for leveraging technology to achieve
                your business goals.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Technology Implementation</h4>
              <p className="card-text">
                We provide end-to-end support for the successful
                implementation of new technologies, ensuring a
                seamless transition and maximum ROI.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Ongoing Support</h4>
              <p className="card-text">
                Our team of skilled IT professionals provide
                ongoing support and maintenance to ensure
                the longevity and reliability of your
                technology solutions.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule a Consultation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submitted && <Alert variant="success">Your consultation request has been submitted successfully!</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control type="tel" name="mobile" placeholder="Enter your mobile number" value={formData.mobile} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formDate">
              <Form.Label>Preferred Date</Form.Label>
              <Form.Control type="date" name="date" value={formData.date} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formTime">
              <Form.Label>Preferred Time</Form.Label>
              <Form.Control type="time" name="time" value={formData.time} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" placeholder="Enter your message" value={formData.message} onChange={handleChange} />
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
};

export default Consulting;