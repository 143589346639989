import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import emailjs from 'emailjs-com';

import Footer from './Footer/Footer';

const TechSolutions = () => {
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    date: '',
    time: '',
    message: ''
  });

  const handleClose = () => {
    setShow(false);
    setSubmitted(false);
  };
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare data for EmailJS
      const emailData = {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        date: formData.date,
        time: formData.time,
        message: formData.message
      };

      // Send email using EmailJS
      await emailjs.send('service_0ibcerg', 'template_rwamlx8', emailData, 'Y-xychfUUEkG6e5gj');

      setSubmitted(true);
      setTimeout(() => {
        setShow(false);
        setSubmitted(false);
      }, 2000);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container my-5" style={{ backgroundColor: '#4169e1' }}>
      <div className="row align-items-center">
        <div className="col-md-6" style={{paddingTop: '20px', paddingBottom: '20px'}}>
          <img src="https://th.bing.com/th/id/OIP.Bj7KvPW5Hs3vDfLVhrt5xQHaEK?rs=1&pid=ImgDetMain" alt="Tech Solutions" className="img-fluid rounded-lg" />
        </div>
        <div className="col-md-6">
          <h1 className="display-4 font-weight-bold mb-4" style={{color:'white'}}><strong>Tech Solutions</strong></h1>
          <p className="lead mb-4" style={{color:'white'}}>
            Our team of skilled technologists and engineers provide
            comprehensive solutions to address your most complex
            business challenges.
          </p>
          <p className="mb-4" style={{color:'white'}}>
            From custom software development to cutting-edge
            cloud-based systems, we leverage the latest
            technologies to drive innovation and transform
            the way you operate.
          </p>
          <div className="d-flex flex-column flex-md-row mb-4">
            <Button variant="btn btn-warning" size="lg" onClick={handleShow} style={{color:'black', borderColor:'black' }}>
              Request a Consultation
            </Button>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Custom Software Development</h4>
              <p className="card-text">
                We build custom software applications tailored to your
                unique business needs, ensuring maximum efficiency and
                productivity.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">Cloud Solutions</h4>
              <p className="card-text">
                Our cloud-based systems and services help you
                streamline your operations, improve scalability,
                and reduce IT infrastructure costs.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <h4 className="card-title">IT Consulting</h4>
              <p className="card-text">
                Our team of experienced IT consultants provide
                strategic guidance and technical expertise to
                help you navigate the ever-evolving technology
                landscape.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request a Consultation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submitted && <Alert variant="success">Your consultation request has been submitted successfully!</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" placeholder="Enter your name" value={formData.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control type="tel" name="mobile" placeholder="Enter your mobile number" value={formData.mobile} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formDate">
              <Form.Label>Preferred Date</Form.Label>
              <Form.Control type="date" name="date" value={formData.date} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formTime">
              <Form.Label>Preferred Time</Form.Label>
              <Form.Control type="time" name="time" value={formData.time} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" placeholder="Enter your message" value={formData.message} onChange={handleChange} />
            </Form.Group>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <Footer />
</div>
  );
};

export default TechSolutions;